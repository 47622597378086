<template>
  <component :is="listComponent" v-bind="$attrs" />
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import useAuth from "@/composables/useAuth";

export default {
  setup() {
    const { isAuth } = useAuth();

    const listComponent = computed(() => {
      if (isAuth.value) {
        return defineAsyncComponent(() =>
          import("@/components/List/ListDefault.vue")
        );
      }

      return defineAsyncComponent(() =>
        import("@/components/List/ListAnonymus.vue")
      );
    });

    return {
      listComponent,
    };
  },
};
</script>
