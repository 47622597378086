<template>
  <div class="content">
    <BaseList
      v-model:filter="currentListFilter.filter"
      v-model:itemsClass="currentListFilter.class"
      v-model:itemsType="currentListFilter.type"
      :items="currentListItems.items"
      :itemsTotalAmount="currentListItems.totalCount"
      :isFetching="currentListItems.isFetching"
      @fetchMore="fetchMore"
    />
    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useIsMobile from "@/composables/useIsMobile";
import useList from "@/composables/useList.js";

import BaseList from "@/components/List/BaseList.vue";
import NavMobile from "@/components/NavMobile.vue";

export default {
  name: "ListNanny", // for <Keep-alive>

  components: { BaseList, NavMobile },

  setup() {
    const { currentListFilter, currentListItems, fetchMore } = useList();
    const { isMobile } = useIsMobile();

    return {
      isMobile,
      currentListFilter,
      currentListItems,
      fetchMore,
    };
  },
};
</script>

<style scoped>
.content {
  padding: 0px 0px 60px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .content {
    padding: 0px;
  }
}
</style>
