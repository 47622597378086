import { useListStore } from "@/stores/list";
import { useUserStore } from "@/stores/user";
import { throttle } from "lodash";
import { storeToRefs } from "pinia";
import { onActivated, onDeactivated } from "vue";
import { ref, watch } from "vue";

export default function useNannyList() {
  const listStore = useListStore();
  const userStore = useUserStore();

  const {
    class: listClass,
    type: listType,
    currentListItems,
    applications,
    offers,
  } = storeToRefs(listStore);
  const { userType } = storeToRefs(userStore);

  const currentListFilter = ref(null);

  watch(
    [listClass, listType],
    ([newListClass, newListType]) => {
      if (newListType === "application") {
        if (newListClass === "nanny") {
          currentListFilter.value = {
            filter: applications.value.nanny.filter,
            class: listClass,
            type: listType,
          };

          return;
        }
      }

      if (newListType === "offer") {
        if (newListClass === "nanny") {
          currentListFilter.value = {
            filter: offers.value.nanny.filter,
            class: listClass,
            type: listType,
          };

          return;
        }
      }

      throw new Error(
        `Wrong listType === ${listType} or listClass === ${listClass}`
      );
    },
    { immediate: true }
  );

  watch([listClass, listType], () => {
    const top = getList(listClass.value, listType.value).windowScrollY;
    setTimeout(() => scrollTo(top), 10);
  });

  watch(userType, (newUserType) => {
    if (newUserType === "client") {
      listType.value = "application";
    }
    if (newUserType === "contractor") {
      listType.value = "offer";
    }
  });

  watch(listStore.applications.nanny.filter, async () => {
    refetch();
  });

  watch(listStore.offers.nanny.filter, async () => {
    refetch();
  });

  onActivated(() => {
    initWindowScrollY();
    window.addEventListener("scroll", handleScroll);
  });

  onDeactivated(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  const onBeforeRefetch = () => {
    resetSavedWindowScrollY();
    resetCurrrentScrollY();
  };

  const handleScroll = () => {
    saveWindowScrollYToListStoreThrottled();
  };

  const initWindowScrollY = () => {
    const currentListScrollY = getCurrentList().windowScrollY;
    if (currentListScrollY) {
      setTimeout(() => scrollTo(currentListScrollY), 10);
    }
  };

  const scrollTo = (top) => {
    window.scrollTo({
      top,
      left: 0,
      behavior: "auto",
    });
  };

  const saveWindowScrollYToListStore = () => {
    const scrollY = window.scrollY;
    saveScrollYToListStore(scrollY);
  };

  const saveWindowScrollYToListStoreThrottled = throttle(
    saveWindowScrollYToListStore,
    150
  );

  const resetSavedWindowScrollY = () => {
    saveScrollYToListStore(0);
  };

  const resetCurrrentScrollY = () => {
    scrollTo(0);
  };

  const saveScrollYToListStore = (scrollY) => {
    getCurrentList().windowScrollY = scrollY;
  };

  const fetchMore = () => {
    if (listType.value === "offer") {
      if (listClass.value === "nanny") {
        if (listStore.offers.nanny.isNext === false) return;

        listStore.fetchOffersNanny();
      }
    }

    if (listType.value === "application") {
      if (listClass.value === "nanny") {
        if (listStore.applications.nanny.isNext === false) return;

        listStore.fetchApplicationsNanny();
      }
    }
  };

  const refetchOffersNannyDebounced =
    listStore.getRefetchOffersNannyDebounced();
  const refetchApplicationsNannyDebounced =
    listStore.getRefetchApplicationsNannyDebounced();

  const refetch = () => {
    onBeforeRefetch();

    if (listType.value === "offer") {
      if (listClass.value === "nanny") {
        refetchOffersNannyDebounced();
      }
    }

    if (listType.value === "application") {
      if (listClass.value === "nanny") {
        refetchApplicationsNannyDebounced();
      }
    }
  };

  const getCurrentList = () => {
    return getList(listClass.value, listType.value);
  };

  const getList = (listClass, listType) => {
    if (listType === "application") {
      if (listClass === "nanny") {
        return listStore.applications.nanny;
      }
    }
    if (listType === "offer") {
      if (listClass === "nanny") {
        return listStore.offers.nanny;
      }
    }
    return null;
  };

  return { currentListFilter, currentListItems, fetchMore };
}
